<template>

	<div class="widget-min-box" :widget-id="widget.id" :widget-type="widget.type">
		<slot v-if="widgetLoad">
			<div class="widget-min-box-content text-center p-2">
				<div class="widget-loading"></div>
			</div>
		</slot>
		<slot v-else>
			<div class="widget-min-box-list" :class="{'min-show': responsive.rightSection}">
				<!-- <slot v-if="activeList && activeList.length"> -->

					<div class="list-widget-search-container">
						<i class="fa fa-search list-widget-search-icon" aria-hidden="true"></i>
						<input class="list-widget-search" type="text" placeholder="فیلتر یادداشت ها" @keyup="filterNotes()" v-model="noteFilterInput">
						<!-- <i class="fa fa-chevron-left list-widget-search-arrow-icon" aria-hidden="true"></i> -->
					</div>

					<div class="list-widget-select-container">
						<i class="uil uil-folder-open list-widget-select-icon"  @click="open_categories_modal()" aria-hidden="true"></i>
						<label class="list-widget-select">
							<select v-model="selectedCategory" @change="setActiveCategory()">
								<option v-for="category in categories" :key="category.id" :value="category.id">{{ category.title }}</option>
							</select>
						</label>
					</div>

					<div class="list-widget list-dash-items list-widget-structure">
						<draggable v-model="activeList" group="notes" @start="drag=true" @end="saveNotesSort()" ghost-class="drag-ghost" handle=".list-drag">
							<template v-for="note in activeList">
								<div :key="note.id" v-if="!note.hide" class="list-row item-draggable hover bold">
									<div class="list-col l-item pointer">
										<i class="uil uil-arrows-v list-drag"></i>
									</div>
									<div class="list-col l-title l-title-widget-structure" @click="openNote(note.id); makeResponsive(2);">
										<span>{{ note.title }}</span>
									</div>
									<div class="list-col l-item blue-color pointer" @click="open_note_edit_modal(note.id)">
										<i class="uil-edit-alt"></i>
									</div>
									<div class="list-col l-item red-color pointer" @click="removeNote(note.id)">
										<i class="uil uil-trash remove-row"></i>
									</div>
								</div>
							</template>
						</draggable>
					</div>

					<div class="list-widget-add-container">
						<i class="fa fa-plus list-widget-add-icon" aria-hidden="true" @click="addNote()"></i>
						<input class="list-widget-input-add" type="text" placeholder="افزودن یادداشت جدید" v-model="newNote" @keyup.enter="addNote()">
					</div>
				<!-- </slot>
				<slot v-else>
					<div class="text-center p-2">
						<div class="widget-loading"></div>
					</div>
				</slot> -->
			</div>

			<div class="widget-min-box-content" :class="{'min-show': responsive.leftSection}">
				<div class="list-row l-head">
					<div class="list-col l-item" @click="makeResponsive(1)">
						<span class="fa fa-arrow-right widget-min-back-to-list"></span>
					</div>
					<div class="list-col l-title">
						<span v-if="activeNote && activeNote.title">{{ activeNote.title }}</span>
						<!-- <i class="uil-edit-alt edit-note" v-if="activeNote && activeNote.title" @click="open_note_edit_modal()"></i> -->
					</div>
					
					<!-- <div class="list-col l-item pointer hover head-color">
						<span class="btn btn-primary btn-sm list-col-left-btn" @click="open_categories_modal()">
							<div class="fa fa-folder l-item-btn-pages"></div>
							مدیریت پوشه ها
						</span>
					</div> -->
					<!-- <div class="list-col l-item pointer hover head-color show-widget-settings-modal" @click="open_settings_modal()" v-if="editable && wrapper != 'dashboard'">
						<span>
							<div class="fa fa-cog l-item-btn-pages"></div>
							تنظیمات
						</span>
					</div> -->
				</div>
				<div class="tgju-widget-content full-padding">
					<froala :tag="'textarea'" :config="editorConfig" class="editor" ref="editor"></froala>
				</div>
			</div>
		</slot>
	</div>

</template>

<style lang="scss" scoped>
	.widget-min-box-content {
		.l-title {
			display: flex;
		}

		.edit-note {
			font-size: 14px;
			cursor: pointer;
			margin-right: -3px;
		}
	}
</style>

<script>
	import draggable from 'vuedraggable'

	export default {
		name: 'Note',
		props: ['widget', 'data', 'editable', 'wrapper'],
		components: {
			draggable
		},
		data () {
			return {
				newNote: '',
				notes: [],
				activeList: [],
				noteFilterInput: '',
				activeNote: {
					id: null,
					user_id: null,
					category_id: null,
					title: '',
					content: '',
					created_at: null,
				},
				categories: [],
				activeCategory: {
					id: null,
					title: '',
				},
				selectedCategory: null,
				allowSave: false,

				editor: null,
				editorConfig: {
					direction: 'ltr',
					language: 'fa',
					charCounterCount: true,
					placeholderText: 'متن خود را تایپ نمایید',
					toolbarButtons: {
						moreText: {
							buttons: ['bold', 'fontSize', 'textColor', 'fontFamily', 'backgroundColor', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'inlineClass'],
							align: 'left',
							buttonsVisible: 1
						},
						moreParagraph: {
							buttons: ['alignLeft', 'alignCenter', 'alignRight', 'formatOLSimple', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
							align: 'left',
							buttonsVisible: 0
						},
						moreRich: {
							buttons: [/*'insertImage',*/ 'emoticons', 'insertLink', 'insertTable', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
							align: 'left',
							buttonsVisible: 2
						},
						moreMisc: {
							buttons: ['undo', 'redo', 'print', /*'getPDF',*/ 'spellChecker'],
							align: 'right',
							buttonsVisible: 1
						}
					},
					toolbarButtonsXS: [
						['undo', 'redo'],
						['bold', 'italic', 'underline']
					],
					imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
					imageUploadURL: this.$helpers.getApiUrl('/note/upload'),
					imageUploadParams: {},
					fileUploadURL: this.$helpers.getApiUrl('/note/upload'),
					fileUploadParams: {},
					events: {
						initialized: () => {
							this.editor = this.$refs.editor.getEditor();
						},
						contentChanged: () => {
							if ( this.allowSave ) {
								this.saveNote();
							}
						},
						focus: () => {
							this.allowSave = true;
						},
						blur: () => {
							this.allowSave = false;
						}
					}
				},
				responsive: {
					rightSection: true,
					leftSection: false,
				},
				widgetLoad: true,
			}
		},

		mounted() {
			this.$watch('data', function(newValue, oldValue) {
				if ( Object.keys(newValue).length && ( typeof oldValue == 'undefined' || !Object.keys(oldValue).length ) ) {
					this.loadData();
				}
			}, {immediate: true});
		},

		methods: {
			loadData() {
				this.categories = this.data.categories;
				if ( this.categories.length ) {
					this.activeCategory = this.categories[0];
				}
				this.selectedCategory = this.activeCategory.id;

				this.notes = this.data.notes;
				if ( typeof this.notes[this.activeCategory.id] != 'undefined' ) {
					this.setActiveList(this.notes[this.activeCategory.id]);
					this.openNote(this.activeList[0].id);
				}
				this.widgetLoad = false;
			},

			setActiveList(list) {
				list = this.$helpers.unbindObject(list);
				
				this.activeList = [];
				list.forEach(note => {
					note.hide = false;
					this.activeList.push(note);
				});
			},

			openNote(id) {
				let index = this.$helpers.getIndex(this.activeList, id);

				this.activeNote = this.$helpers.unbindObject(this.activeList[index]);

				if ( this.editor ) {
					this.editor.html.set(this.activeNote.content);
					this.editor.undo.reset();
				}
			},

			addNote() {
				if ( !this.newNote ) return;

				if ( this.noteFilterInput ) {
					this.noteFilterInput = '';
					this.filterNotes();
				}

				let id = 'id-' + new Date().getTime();

				let index = this.activeList.push({
					id,
					user_id: null,
					category_id: this.activeCategory.id,
					title: this.newNote,
					content: '',
					created_at: null,
					hide: false
				});

				let api_data = {
					title: this.newNote,
					category_id: this.activeCategory.id,
				};

				this.$helpers.makeRequest('POST', '/note/save', api_data).then(api_response => {
					this.$helpers.api_alert(api_response);

					if ( api_response.status == 200 ) {
						let inserted_id = api_response.data.response.id;

						this.activeList.forEach((note, index) => {
							if ( note.id == id ) {
								note.id = inserted_id;
								
								this.updateSource();
							}
						});
					}
				});

				this.newNote = '';
				this.openNote(id);
			},

			saveNote() {
				if ( !this.activeNote.id ) return;
				this.activeNote.content = this.editor.html.get(true);

				let index = this.$helpers.getIndex(this.activeList, this.activeNote.id);
				this.activeList[index].content = this.activeNote.content;


				let api_data = {
					id: this.activeNote.id,
					title: this.activeNote.title,
					content: this.activeNote.content
				};

				this.$helpers.makeRequest('POST', '/note/save', api_data).then(api_response => {
					this.$helpers.api_alert(api_response);

					if ( api_response.status == 200 ) {
						//
					}
				});
			},

			removeNote(id) {
				this.$helpers.confirm('هشدار حذف!', {
					message: 'آیا از حذف این مورد اطمینان دارید؟',
				}).then((confirmed) => {
					if ( confirmed ) {
						let index = this.$helpers.getIndex(this.activeList, id);

						this.activeList.splice(index, 1);
						
						let next_index = index - 1;
						if ( next_index < 0 ) next_index = 0;

						if ( typeof this.activeList[next_index] != 'undefined' ) {
							this.openNote(this.activeList[next_index].id);
						} else {
							this.resetActiveNote();
						}

						this.$helpers.makeRequest('DELETE', '/note/delete/' + id).then(api_response => {
							this.$helpers.api_alert(api_response);

							if ( api_response.status == 200 ) {
								this.updateSource();
							}
						});	
					}
				});
			},

			resetActiveNote() {				
				this.activeNote.id = null;
				this.activeNote.user_id = null;
				this.activeNote.category_id = null;
				this.activeNote.title = '';
				this.activeNote.content = '';
				this.activeNote.created_at = null;
				
				this.editor.html.set(this.activeNote.content);
				this.editor.undo.reset();
			},

			saveNotesSort() {
				let sort = [];
				this.activeList.forEach(note => {
					sort.push(note.id);
				});

				let api_data = {
					sort: JSON.stringify(sort),
				};

				this.$helpers.makeRequest('POST', '/note/sort', api_data).then(api_response => {
					this.$helpers.api_alert(api_response);

					if ( api_response.status == 200 ) {
						//
					}
				});
			},

			open_settings_modal() {
				let callbackEvent = this.$router.currentRoute.name + '_save_settings_' + this.widget.id + '_' + new Date().getTime();

				let modal_data = {
					modal: 'widget_settings_modal',
					callbackEvent,
					component: 'Settings/Note.vue',
					config: {
						data: {
							title: this.widget.title,
						}
					}
				};

				modal_data = this.$helpers.unbindObject(modal_data);

				this.$root.$emit('open_modal', modal_data);

				this.$root.$on(callbackEvent, (settings) => {
					this.widget.title = settings.title;

					let widget = this.$helpers.unbindObject(this.widget);

					this.$helpers.saveWidget(widget);
				});
			},
			
			open_categories_modal() {
				let callbackEvent = this.$router.currentRoute.name + '_manageNoteCategories_' + new Date().getTime();

				let modal_data = {
					modal: 'general_modal',
					callbackEvent,
					component: 'NoteCategories.vue',
					config: {
						title: 'مدیریت پوشه‌ها',
						icon: 'uil uil-folder-open with-icon',
						smallModal: true,
						data: {
							widget: this.widget,
							categories: this.categories
						},
					}
				};

				this.$root.$emit('open_modal', modal_data);

				this.$root.$on(callbackEvent, (response) => {
					if ( response.action == 'addCategory' ) {
						this.categories.push(response.data.category);
					} else if ( response.action == 'editCategory' ) {
						let index = this.$helpers.getIndex(this.categories, response.data.id);

						this.categories.filter((category, index) => {
							if ( category.id == response.data.category.id ) {
								category.title = response.data.category.title;
							}
						});
					} else if ( response.action == 'removeCategory' ) {
						let index = this.$helpers.getIndex(this.categories, response.data.id);
						this.categories.splice(index, 1);
					}
				});
			},
			
			open_note_edit_modal(id = null) {
				let callbackEvent = this.$router.currentRoute.name + '_EditNotes_' + new Date().getTime();

				if (id > 0) {
					let index = this.$helpers.getIndex(this.activeList, id);
					this.activeNote = this.activeList[index];
				}
				
				let modal_data = {
					modal: 'general_modal',
					callbackEvent,
					component: 'NoteEdit.vue',
					config: {
						title: 'ویرایش یادداشت',
						icon: 'uil uil-edit-alt with-icon',
						smallModal: true,
						data: {
							note: this.$helpers.unbindObject(this.activeNote),
							categories: this.$helpers.unbindObject(this.categories)
						},
					}
				};

				this.$root.$emit('open_modal', modal_data);

				this.$root.$on(callbackEvent, (response) => {
					if ( response.action == 'editNote' ) {
						if ( this.noteFilterInput ) {
							this.noteFilterInput = '';
							this.filterNotes();
						}

						this.activeNote.title = response.data.note.title;
						this.activeNote.category_id = response.data.note.category_id;

						let categoryChanged = ( this.activeNote.category_id != this.activeCategory.id ) ? true : false;
						
						this.activeList.forEach((note, index) => {
							if ( note.id == this.activeNote.id ) {
								note.title = this.activeNote.title;
								note.category_id = this.activeNote.category_id;

								if ( categoryChanged ) {
									this.activeList.splice(index, 1);

									if ( typeof this.notes[this.activeNote.category_id] == 'undefined' ) {
										this.notes[this.activeNote.category_id] = [];
									}

									this.notes[this.activeNote.category_id].push(note);
								}
							}
						});

						let api_data = {
							title: this.activeNote.title,
							category_id: this.activeNote.category_id,
						};

						this.$helpers.makeRequest('POST', '/note/edit/' + this.activeNote.id, api_data).then(api_response => {
							this.$helpers.api_alert(api_response);

							if ( api_response.status == 200 ) {
								this.updateSource();
							}
						});
					}
				});
			},

			setActiveCategory() {
				this.categories.forEach(category => {
					if ( category.id == this.selectedCategory ) {
						this.activeCategory = category;
					}
				});
				
				if ( this.noteFilterInput ) {
					this.noteFilterInput = '';
					this.filterNotes();
				}
				
				if ( typeof this.notes[this.activeCategory.id] != 'undefined' ) {
					this.setActiveList(this.notes[this.activeCategory.id]);
					// this.openNote(this.activeList[0].id);
				} else {
					this.activeList = [];
					this.resetActiveNote();
				}
			},

			filterNotes() {
				let activeList = [];
				Object.values(this.notes).forEach((list, listIndex) => {
					list.forEach((note, index) => {
						if ( note.title.indexOf(this.noteFilterInput.trim()) > -1 ) {
							activeList.push(note);
						}
					});
				});

				if ( !this.noteFilterInput.trim() ) {
					if ( typeof this.notes[this.activeCategory.id] != 'undefined' ) {
						activeList = this.notes[this.activeCategory.id];
					} else {
						activeList = [];
					}
				}

				this.setActiveList(activeList);

				// if ( this.activeList.length ) {
				// 	this.openNote(this.activeList[0].id);
				// } else {
				// 	this.resetActiveNote();
				// }
			},

			updateSource() {
				this.notes[this.activeCategory.id] = this.activeList;
			},

			makeResponsive(mode) {
				if ( mode == 1 ) {
					this.responsive.rightSection = true;
					this.responsive.leftSection = false;
				} else if ( mode == 2 ) {
					this.responsive.rightSection = false;
					this.responsive.leftSection = true;
				} else if ( mode == 3 ) {
					this.responsive.rightSection = false;
					this.responsive.leftSection = false;
				}
			}
		}
	}
</script>